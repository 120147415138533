var rl,
rellax = {
	settings: {

	},
	init: function() {
		rl = this.settings;
		this.bindUIActions();
		console.log('rellax loaded!');
	},
	bindUIActions: function() {
		if (document.getElementsByClassName('rellax').length != 0) {
			var rellax = new Rellax('.rellax', {
				speed: -2,
				center: false,
				wrapper: null,
				round: true,
				vertical: true,
				horizontal: false
			});
		}
	}
};
