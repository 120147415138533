document.addEventListener("DOMContentLoaded", function() {

    'use strict';

    // code
    // accordions.init();
    // cookieNotice.init();
    // notification.init();
    // randomQuote.init();
    glide.init();
    rellax.init();
    AOS.init();

    $('.hamburger').on('click', function() {
        $(this).toggleClass('is-active');
        $('.nav').toggleClass('is-active');
    });

    var scrollTop     = $(window).scrollTop();

    $(window).on('scroll', function() {
        scrollTop = $(window).scrollTop()

        if (scrollTop >= 10) {
            $('.header').addClass('bg-active');
        } else {
            $('.header').removeClass('bg-active');
        }
    });

    $('.activate-share-icons').on('click', function(e) {
        e.preventDefault();
        $(this).closest('.sharing').toggleClass('active');
    });

});
