var as,
aos = {
	settings: {
		// slide: $('.slide'),
		// slideList: $('.slide-list')
	},
	init: function() {
		as = this.settings;
		this.bindUIActions();
		console.log('aos loaded!');
	},
	bindUIActions: function() {

	}
};
