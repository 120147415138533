var gl,
glide = {
	settings: {

	},
	init: function() {
		gl = this.settings;
		this.bindUIActions();
		console.log('glide loaded!');
	},
	bindUIActions: function() {
		$('.slick-logos').slick({
			infinite: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
			arrows: false,
			pauseOnHover: true,
			responsive: [
			    {
			      breakpoint: 768,
			      settings: {
			        slidesToShow: 3,
			        slidesToScroll: 1,
			      }
			    },
			    {
			      breakpoint: 680,
			      settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
			      }
			    },
			  ]
		});

		$('.slick').slick({
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
			arrows: false,
			centerMode: true,
			centerPadding: '0',
			pauseOnHover: true,
			responsive: [
			    {
			      breakpoint: 768,
			      settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1,
					centerPadding: '80px',
			      }
			    },
			    {
			      breakpoint: 680,
			      settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerPadding: '80px',
			      }
			    },
			    {
			      breakpoint: 480,
			      settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1
			      }
			    }
			    // You can unslick at a given breakpoint now by adding:
			    // settings: "unslick"
			    // instead of a settings object
			  ]
		});

		// var glide = new Glide('.glide', {
		// 	type: 'carousel',
		// 	autoplay: 3000,
		// 	hoverpause: true,
		// 	gap: 40,
		// 	focusAt: 'center',
		// 	startAt: 0,
		// 	perView: 3
		// });
		//
		// glide.mount();
	}
};
